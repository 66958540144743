import MainLayout from 'app/components/MainLayout';
import React from 'react';
import AccountInfo from './components/AccountInfo';
import SheetsBlock from './components/SheetsBlock';
import SheetsBlockMobile from './components/SheetsBlockMobile';

const Sheets = () => {
  return (
    <MainLayout className='theme-light'>
      <div className='container sheets-page'>
        <AccountInfo />
        <SheetsBlock />
        <SheetsBlockMobile />
      </div>
    </MainLayout>
  );
};

export default Sheets;
