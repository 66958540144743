import { createReducer } from 'utils/reduxHelpers';
import { Api, setAuthToken } from 'utils/connectors';
import { setUserAppPermissions, clearPermissions } from 'utils/permissionHelper';
import { getLocalToken, removeLocalToken, saveLocalToken } from 'utils/tokenHelpers';
import { removeFromStore } from 'utils/storeHelpers';

const ON_AUTH_SATE = '@@auth/ON_AUTH_SATE';
const ON_LOGIN = '@@auth/ON_LOGIN';
const ON_LOGOUT = '@@auth/ON_LOGOUT';
const ON_CONFIG = '@@auth/ON_CONFIG';

const onAuthState = payload => ({ type: ON_AUTH_SATE, payload });
export const onLogin = payload => ({ type: ON_LOGIN, payload });
const onLogout = () => ({ type: ON_LOGOUT });
export const onConfig = payload => ({ type: ON_CONFIG, payload });

const isAuthHandlers = {
  [ON_AUTH_SATE]: (state, action) => action.payload,
  [ON_LOGOUT]: () => null,
};

const accountHandlers = {
  [ON_LOGIN]: (state, action) => action.payload,
  [ON_LOGOUT]: () => null,
};

const configHandlers = {
  [ON_CONFIG]: (state, action) => action.payload,
};

const clearForLogout = dispatch => {
  dispatch(onLogout());
  removeLocalToken();
  clearPermissions();
  dispatch(onAuthState(0));
};

export const getProfile = async () => {
  try {
    const { data } = await Api.get('/users/me/profile');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const afterLoginAction = async dispatch => {
  try {
    const user = await getProfile();
    await dispatch(onLogin(user));
    setUserAppPermissions(1);
    dispatch(onAuthState(1));
  } catch (err) {
    dispatch(onAuthState(0));
    clearForLogout(dispatch);
  }
};

const getConfigurations = async dispatch => {
  try {
    const { data } = await Api.get('/common/configuration');
    dispatch(onConfig(data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getAuthState = () => async dispatch => {
  await getConfigurations(dispatch);
  const token = getLocalToken();

  if (token) {
    setAuthToken(token, Api);
    await afterLoginAction(dispatch);
  } else dispatch(onAuthState(0));
};

export const loginUser = data => async dispatch => {
  try {
    const { token } = data;
    saveLocalToken(token);
    setAuthToken(token, Api);
    await afterLoginAction(dispatch);
  } catch (err) {
    removeFromStore('cart');
  }
};

export const logout = () => async dispatch => {
  try {
    await Api.post('/users/me/logout');
    setAuthToken('', Api);
  } catch (err) {
  } finally {
    clearForLogout(dispatch);
  }
};

export const isAuthInitialState = null;
export const accountInitialState = {};

export const isAuthReducer = createReducer(isAuthInitialState, isAuthHandlers);
export const accountReducer = createReducer(accountInitialState, accountHandlers);
export const configReducer = createReducer({}, configHandlers);
