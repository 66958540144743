import React from 'react';
import { Route } from 'react-router-dom';

export let userPermissions = {};

export const permissions = {
  // Admin
  0: ['public'],
  // Vendor
  1: ['auth'],
};

export const setUserAppPermissions = role => {
  permissions[role].forEach(item => {
    userPermissions[item] = true;
  });
};

export const hasAccess = name => {
  return !!userPermissions[name];
};

export const PRoute = props => {
  if (props.access && !hasAccess(props.access)) return null;
  return <Route {...props} />;
};

export const clearPermissions = () => {
  userPermissions = {};
};
