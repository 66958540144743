/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef } from 'react';
import IconEyeSlash from 'assets/icons/eye.svg';

const InputGroup = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  type,
  disabled,
  required,
  className,
  containerClass,
  inputClass = '',
  successText,
  errorText,
  min,
  max,
  hideEyeButton,
  label,
  showOnlyValue,
}) => {
  const fieldRef = useRef();
  const changeInputType = () =>
    (fieldRef.current.type = fieldRef.current.type === 'password' ? 'text' : 'password');

  const setInputClasses = () => {
    let classes = 'form-control';
    if (inputClass) classes += ` ${inputClass}`;
    if (value) classes += ' active';
    return classes;
  };

  const handleBlur = e => {
    let valueHasUpdated = false;
    if (type === 'number' && e.target.value) {
      if (min && e.target.value < min) {
        e.target.value = min;
        valueHasUpdated = true;
      }
      if (max && e.target.value > max) {
        e.target.value = max;
        valueHasUpdated = true;
      }
    }
    valueHasUpdated && onChange(e);
    onBlur && onBlur(e);
  };

  if (showOnlyValue) return <span>{value}</span>;

  return (
    <div className={`form-group form-field-container ${containerClass || ''}`}>
      <div
        className={`form-field position-relative${errorText ? ' is-error' : ''}${
          successText ? ' is-success' : ''
        } ${className || ''}`}
      >
        {label && (
          <label className='font-weight-bold ml-2 mb-1' htmlFor={name}>
            {label}
          </label>
        )}
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          minLength={min}
          maxLength={max}
          autoComplete='off'
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          className={setInputClasses()}
          ref={fieldRef}
        />
        {type === 'password' && !hideEyeButton && (
          <img
            src={IconEyeSlash}
            alt='eye slash'
            className='eye-icon'
            onClick={changeInputType}
            role='presentation'
          />
        )}
      </div>
      {errorText && errorText !== true && (
        <p className='text-danger mt-1 mb-0 position-absolute w-100' data-testid='dangerText'>
          {errorText}
        </p>
      )}
    </div>
  );
};

export default InputGroup;
