/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';
import CardBox from 'shared/components/CardBox';
import { IconEye, IconEyeRemoved } from 'shared/components/Icons';

const Leaders = ({ leaders, userOption, setUserOption, setAllGames, setWeek }) => {
  const isAuth = useSelector(state => state.isAuthenticated);

  const onSelectUser = (user, isActive, color, e, isPrevious) => {
    if (isActive) {
      setUserOption({ userId: null });
      setAllGames(!isAuth);
    } else {
      setAllGames(false);
      setUserOption({
        userId: user.user_id,
        sheetId: user.sheet_id,
        color,
        name: `${user.firstname} ${user.lastname}`,
      });
      if (isPrevious) {
        setWeek(w => (w - 1 > 0 ? w - 1 : w));
      }
    }
  };

  return (
    <div>
      <h2 className='landing-title text-center pl-0 pl-sm-5 text-sm-left'>Leaders</h2>

      <CardBox
        className='mb-0 mb-sm-7 pb-0 pb-sm-3 landing-score-box'
        headerClassName='blue'
        title='Weekly Leaders'
      >
        {leaders.currentWeekData && !!Object.keys(leaders.currentWeekData).length ? (
          Object.keys(leaders.currentWeekData).map((name, key) => {
            const data = leaders.currentWeekData[name];
            return (
              <div className='mb-2' key={key}>
                <div className='d-flex first-row w-100 justify-content-between'>
                  <p className='px-5 px-sm-6'>{name}</p>
                  <p className='pr-8 pr-sm-10'>Score</p>
                </div>
                {data.map((item, i) => {
                  const userSelected =
                    item.user_id === userOption.userId && item.sheet_id === userOption.sheetId;
                  return (
                    <div
                      className={`d-flex w-100 score-row justify-content-between ${
                        userSelected ? 'active' : ''
                      }`}
                      onClick={onSelectUser.bind(null, item, userSelected, 'blue')}
                      key={i}
                    >
                      <p className='mb-0'>
                        {item.team_rank} {item.firstname} {item.lastname}
                      </p>
                      <div className='d-flex align-items-center'>
                        <p className='text-blue mb-0 mr-2'>{item.wts}</p>
                        {userSelected ? <IconEyeRemoved width='20' /> : <IconEye />}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        ) : (
          <span className='d-block text-center mb-2'>Results Pending</span>
        )}
      </CardBox>
      <CardBox
        className='mb-0 mb-sm-7 pb-0 pb-sm-3 landing-score-box'
        title='Previous Week Winners'
        headerClassName='orange'
      >
        {leaders.lastWeekData && !!Object.keys(leaders.lastWeekData).length ? (
          Object.keys(leaders.lastWeekData).map((name, key) => {
            const data = leaders.lastWeekData[name];
            return (
              <div className='mb-2' key={key}>
                <div className='d-flex first-row w-100 justify-content-between'>
                  <p className='px-5 px-sm-6'>{name}</p>
                  <p className='pr-8 pr-sm-10'>Score</p>
                </div>
                {data.map((item, i) => {
                  const userSelected =
                    item.user_id === userOption.userId && item.sheet_id === userOption.sheetId;
                  return (
                    <div
                      className={`d-flex w-100 score-row justify-content-between ${
                        userSelected ? 'active' : ''
                      }`}
                      onClick={onSelectUser.bind(null, item, userSelected, 'orange', true)}
                      key={i}
                    >
                      <p className='mb-0'>
                        {item.team_rank} {item.firstname} {item.lastname}
                      </p>
                      <div className='d-flex align-items-center'>
                        <p className='text-orange mb-0 mr-2'>{item.wts}</p>
                        {userSelected ? <IconEyeRemoved width='20' /> : <IconEye />}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        ) : (
          <span className='d-block mb-0 mb-sm-2 text-center'>Results Pending</span>
        )}
      </CardBox>
      <CardBox className='mb-10 season-leader-block' title='Season Leaders' headerClassName='red'>
        {leaders.seasonData ? (
          Object.keys(leaders.seasonData).map((name, key) => {
            const data = leaders.seasonData[name];
            return (
              <div key={key}>
                <p className='d-flex mb-0 justify-content-between'>
                  <span className='px-5 px-sm-6'>{name}</span>
                  <span className='pr-9'>Points</span>
                </p>
                <ul className='list-unstyled'>
                  {data.map((item, i) => {
                    const userSelected =
                      item.user_id === userOption.userId && item.sheet_id === userOption.sheetId;
                    return (
                      <li
                        key={i}
                        className={`d-flex justify-content-between align-items-center ${
                          userSelected ? 'active' : ''
                        }`}
                        onClick={onSelectUser.bind(null, item, userSelected, 'red')}
                      >
                        <div>
                          {item.team_rank} {item.firstname} {item.lastname}
                        </div>
                        <div className='d-flex align-items-center'>
                          <p className='text-red mb-0 mr-3'>{item.swp}</p>
                          {userSelected ? <IconEyeRemoved width='20' /> : <IconEye />}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })
        ) : (
          <span className='d-block mt-0 mt-sm-3'>Results Pending</span>
        )}
      </CardBox>
    </div>
  );
};

export default Leaders;
