import React from 'react';

const CardBox = ({ title, className, children, headerClassName, poolName }) => {
  return (
    <div className={`card-box ${className}`}>
      <div
        className={`card-header align-items-center d-flex justify-content-between ${headerClassName ||
          ''}`}
      >
        <h3 className='text-nowrap'>{title}</h3>
        {poolName && <span className='text-nowrap'>Pool Name: {poolName}</span>}
      </div>
      <div className='card-body'>{children}</div>
    </div>
  );
};

export default CardBox;
