import medal1 from 'assets/icons/medal-1.svg';
import medal2 from 'assets/icons/medal-2.svg';
import medal3 from 'assets/icons/medal-3.svg';
import medalL from 'assets/icons/medal-l.svg';
import medalNull from 'assets/icons/medal-null.svg';

export const seasonPosIcons = {
  0: medalL,
  1: medal1,
  2: medal2,
  3: medal3,
  4: medalNull,
};

export const getError = err => {
  if (err?.response?.data?.message) {
    const msg = err.response.data.message;
    return typeof msg === 'string' ? msg : msg.message;
  }
  if (err?.response?.data?.error) {
    const msg = err.response.data.error;
    return typeof msg === 'string' ? msg : msg.message;
  }
  if (err && err.response && err.response.statusText) {
    return err && err.response && err.response.statusText;
  }
  if (err && err.data && err.data.message) {
    return err.data.message;
  }
  if (err && err.message) {
    return err.message;
  }
  return err;
};

export const isFromKickDomain = () => {
  const origin = window.location.origin;
  return origin.includes('knockout');
};

export const getGameState = currentWeekData => {
  return currentWeekData === undefined || currentWeekData.points === null
    ? null
    : currentWeekData.points === 0
    ? 'loss'
    : currentWeekData.points === 1
    ? 'tie'
    : 'win';
};

export const showPrice = price => {
  return price ? `$${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}` : '0';
};
