import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { logout } from 'app/actions';
import userIcon from 'assets/icons/user.svg';
import { isFromKickDomain } from 'utils/appHelpers';
import { HOST } from 'configs';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.isAuthenticated);
  const account = useSelector(state => state.account?.profile);
  const config = useSelector(state => state.config);
  const isKick = isFromKickDomain();

  const onLogoutClick = () => {
    dispatch(logout());
  };

  const navbar = [
    { name: 'Home', show: isAuth, route: '/home' },
    { name: 'Sheets', show: isAuth, route: '/sheets' },
  ];

  const goHomePage = () => {
    history.push('/home');
  };

  return (
    <header className='d-none d-sm-block'>
      <div className='container h-100 d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          <h1 className='font-weight-bold m-0 pointer' onClick={goHomePage}>
            <img width='40px' src={config.logo} alt='logo' className='mr-2' />
          </h1>
          <ul className='d-flex mb-0 ml-1 list-unstyled justify-content-between'>
            {navbar.map(
              ({ name, route, show }, i) =>
                !!show && (
                  <li className='ml-6' key={i}>
                    <NavLink className='text-nowrap' to={route}>
                      {name}
                    </NavLink>
                  </li>
                ),
            )}
          </ul>
        </div>
        {!isAuth && (
          <div>
            {isKick && (
              <a className='btn text-info font-weight-bold' href={HOST.API.APP_URL}>
                HOME
              </a>
            )}
            {!isKick && (
              <Link className='btn text-info font-weight-bold' to='/auth/singin'>
                LOG IN
              </Link>
            )}
            <Link className='btn btn-info-light' to='/auth/signup'>
              SIGN UP
            </Link>
          </div>
        )}
        {!!isAuth && (
          <div className='d-flex align-items-center'>
            <Link to='/sheets' className='text-secondary d-flex align-items-center mr-2'>
              <span className='name'>
                {account.firstname} {account.lastname}
              </span>
              <img src={userIcon} className='ml-3' alt='user' />
            </Link>
            <button className='btn text-info font-weight-bold' onClick={onLogoutClick}>
              LOG OUT
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
