import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getAuthState } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import { isFromKickDomain } from 'utils/appHelpers';

import Landing from './routes/Landing';
import ScrollToTop from 'shared/components/ScrollToTop';
import Loading from 'shared/components/Loading';
import Auth from './routes/Auth';
import Profile from './routes/Profile';
import Sheets from './routes/Sheets';
import SheetView from './routes/SheetView';
import KickPDF from './routes/KickPDF';
import PooSurvivalStatsView from './routes/PooSurvivalStatsView';

const App = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.isAuthenticated);

  useEffect(() => {
    dispatch(getAuthState());
    //eslint-disable-next-line
  }, []);

  if (isAuth === null) return <Loading className='mt-5' />;

  if (isFromKickDomain()) return <KickPDF />;

  return (
    <ScrollToTop>
      <Switch>
        {!isAuth && <Route path='/auth' component={Auth} />}
        {isAuth && <Route path='/profile' component={Profile} />}
        {isAuth && <Route path='/sheets' component={Sheets} />}
        {isAuth && <Route path='/sheet/:id/view' component={SheetView} />}
        <Route path='/pools/survival/:id/stats' component={PooSurvivalStatsView} />
        <Route path='/home' component={Landing} />
        <Redirect from='*' to='/home' />
      </Switch>
    </ScrollToTop>
  );
};

export default App;
