import React from 'react';
import fundraisingMan from 'assets/fundraising-man.png';
import { IconFundraising } from './Icons';
import { showPrice } from 'utils/appHelpers';

const TopBanner = ({ pool, week }) => {
  const percent = pool?.pool_stats?.raised / pool?.pool_config?.bucket_mgmt?.administrative_amount;
  const percentage = (percent * 100).toFixed(2) || 0;

  return (
    <div className='landing-fundraising d-flex flex-row'>
      <div className='fundraising-wrapper d-flex align-items-center justify-content-between w-100'>
        <div className='img-area col-7 text-center d-none d-md-block'>
          <img src={fundraisingMan} alt='Fundraising' />
        </div>
        <div className='content-area flex-fill h-100 d-flex flex-column justify-content-between'>
          <div className='info-block'>
            <div className='d-flex'>
              <IconFundraising />
              <p className='mb-1'>
                <p className='title'>{pool?.pool_config?.name}</p>
                <p className='desc mb-0'>{pool?.pool_config?.description}</p>
              </p>
            </div>
          </div>
          <div className='progress-block mt-6'>
            <p className='d-flex align-items-center justify-content-between fz-16 mb-2'>
              <p className='mb-0'>
                <b className='mr-2'>Week:</b> {week} / 18
              </p>
              <p className='mb-0'>
                <b className='mr-2'>Participants:</b> {pool?.pool_stats?.sold_sheets || 0}
              </p>
            </p>
            <div className='progress mb-2'>
              <div
                className='progress-bar'
                style={{ width: `${percentage}%` }}
                role='progressbar'
              />
            </div>
            <p className='d-flex align-items-center justify-content-between fz-20 mb-0'>
              <p className='mb-0'>
                Target: {showPrice(pool?.pool_config?.bucket_mgmt?.administrative_amount)}
              </p>
              <p className='mb-0 fw-600 text-light-green'>
                Raised: {showPrice(pool?.pool_stats?.raised)} ({percentage}%)
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
