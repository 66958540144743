import React, { useEffect, useRef, useState } from 'react';
import { HOST } from 'configs/host';
import { Document, Page, pdfjs } from 'react-pdf';
import MainLayout from 'app/components/MainLayout';
import { IconDownload } from 'shared/components/Icons';

const pdfVersion = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfVersion;

const KickPDF = () => {
  const [numPages, setNumPages] = useState(null);
  const [width, setWidth] = useState();
  const pdfView = useRef();
  const fileUrl = `${HOST.API.CONTENT_URL}/knockout/ko-pool.pdf`;

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    setWidth(pdfView.current.clientWidth - 20);
  }, []);

  return (
    <MainLayout>
      <div className='kick-pdf-page container'>
        <div className='my-5 text-right'>
          <a href={fileUrl} className='btn btn-white' download='ko-pool.pdf'>
            <IconDownload />
          </a>
        </div>
        <div className='pdf-view-box' ref={pdfView}>
          {fileUrl && width && (
            <Document file={fileUrl} onLoadSuccess={onLoadSuccess} id='kick'>
              {Array.from(new Array(numPages), (el, index) => (
                <Page width={width} key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default KickPDF;
