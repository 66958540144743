import React from 'react';
import CardBox from 'shared/components/CardBox';
import moment from 'moment';
import { useSelector } from 'react-redux';

const checkIsActive = (t1, t2, sheets) => {
  let result = false;
  sheets.forEach(sheet => {
    if (!result && sheet.weeks) {
      sheet.weeks.forEach(week => {
        const names = [week.t1_name, week.t2_name, week.t3_name];
        if ((names.includes(t1) || names.includes(t2)) && !result) {
          result = true;
        }
      });
    }
  });
  return result;
};

const GameInfo = ({ games, teams, sheets, userOption, allGames, setAllGames }) => {
  const isAuth = useSelector(state => state.isAuthenticated);
  const { accountSheets, selectedUserSheets } = sheets;

  return (
    <div>
      {!!games.length && (
        <div className='pl-1 d-flex align-items-center justify-content-between'>
          <h2 className='landing-title d-flex text-nowrap pl-1 pl-sm-4 flex-fill justify-content-end justify-content-sm-start align-items-center'>
            {!allGames ? (userOption.name ? `${userOption.name} Games` : 'My Games') : 'Games'}
          </h2>
          {(!!isAuth || userOption.name) && (
            <div className='form-check mr-3 game-checkbox'>
              <label className='form-check-label mr-6 text-nowrap' htmlFor='onlyMy'>
                All Games
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                checked={allGames}
                onChange={e => setAllGames(i => !i)}
                id='onlyMy'
              />
            </div>
          )}
        </div>
      )}
      {games.map((item, i) => {
        const date = moment(item['Date']).format('ddd MM/DD - h:mm A');
        const homeTeam = teams[item['HomeTeam']];
        const awayTeam = teams[item['AwayTeam']];

        const accountSheetsActive = accountSheets
          ? checkIsActive(homeTeam.Key, awayTeam.Key, accountSheets)
          : false;
        const isAccountActive = allGames && isAuth && accountSheetsActive;

        const currentSheets =
          userOption.userId && selectedUserSheets ? selectedUserSheets : accountSheets;
        const isActive = currentSheets
          ? checkIsActive(homeTeam.Key, awayTeam.Key, currentSheets)
          : false;

        if (!allGames && !isActive) return null;

        return (
          <CardBox
            key={i}
            className={`mb-7 game-info-card ${isActive || isAccountActive ? 'active' : ''} ${
              isActive && userOption.color ? 'color-' + userOption.color : ''
            }`}
            title={`${date} - ${item['StadiumDetails.Name']}`}
          >
            <div className='game-info-body'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='col p-0 d-flex align-items-center'>
                  <div className='team-image'>
                    <img height='30' src={awayTeam && awayTeam['WikipediaLogoUrl']} alt='imgl' />
                  </div>
                  <p className='team-name ml-2 ml-sm-3 m-0'>{awayTeam && awayTeam['FullName']}</p>
                </div>
                <div className='col-3 p-0 text-center team-score'>
                  {item.AwayScore} - {item.HomeScore}
                </div>
                <div className='col justify-content-end p-0 d-flex align-items-center'>
                  <p className='team-name mr-2 mr-sm-3 m-0 text-right'>
                    {homeTeam && homeTeam['FullName']}
                  </p>
                  <div className='team-image'>
                    <img height='30' src={homeTeam && homeTeam['WikipediaLogoUrl']} alt='imgl' />
                  </div>
                </div>
              </div>
            </div>
          </CardBox>
        );
      })}
    </div>
  );
};

export default GameInfo;
