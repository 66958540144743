import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import authImg from 'assets/auth.png';
import Signin from './routes/Signin';
import Signup from './routes/Signup';
import Forgot from './routes/Forgot';
import { IconBackArrow } from 'shared/components/Icons';

const Auth = ({ history }) => {
  const goBack = () => {
    history.push('/home');
  };

  return (
    <div className='auth-page d-flex flex-column flex-sm-row'>
      <div className='mobile-header d-md-none d-block d-flex justify-content-center align-items-end'>
        <button className='btn back-icon p-0 btn-non-shadow' onClick={goBack}>
          <IconBackArrow />
        </button>
        NFL-2024
      </div>
      <div className='page-image d-none d-md-block'>
        <img width='100%' src={authImg} alt='auth' />
      </div>
      <div className='page-form flex-fill'>
        <Switch>
          <Route path='/auth/signin' component={Signin} />
          <Route path='/auth/signup' component={Signup} />
          <Route path='/auth/forgot' component={Forgot} />
          <Redirect from='*' to='/auth/signin' />
        </Switch>
      </div>
    </div>
  );
};

export default Auth;
