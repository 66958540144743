import { combineReducers } from 'redux';
import { isAuthReducer, accountReducer, configReducer } from 'app/actions';
import { landingReducer } from 'app/routes/Landing/actions';

// Load reducers
const rootReducer = combineReducers({
  isAuthenticated: isAuthReducer,
  account: accountReducer,
  landing: landingReducer,
  config: configReducer,
});

export default rootReducer;
