import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from 'shared/components/Loading';

const SheetAssignModal = ({ close, sheetID, fundrasingName, urlPath }) => {
  const isAuth = useSelector(state => state.isAuthenticated);
  const [fetch, setFetch] = useState(false);
  const [success, setSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onAssignSheet = async e => {
    try {
      setFetch(true);
      await Api.post('/users/me/assign', { url_path: urlPath, sheets: [sheetID] });
      setSuccess(true);
    } catch (err) {
      close();
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    if (isAuth && sheetID) {
      onAssignSheet();
    }
  }, []);

  return (
    <div className='modal-box alert-modal d-flex flex-column'>
      <div className='m-header'>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-contentflex-fill h-100 d-flex flex-column justify-content-between'>
        {!isAuth && (
          <p className='pt-3'>
            The sheet with <b>{sheetID}</b> from fundraising "<b>{fundrasingName}</b>" will be
            assigned after Signing In. If you don't have account first you need to Sign Up.
          </p>
        )}
        {success && (
          <p className='pt-3'>
            Sheet with ID <b>{sheetID}</b> from the funderising "<b>{fundrasingName}</b>" is
            successfully assigned.
          </p>
        )}
        {fetch && isAuth && <Loading />}
        <div className='text-center mt-4'>
          {!isAuth && (
            <>
              <Link
                to={{
                  pathname: '/auth/signin',
                  state: { sheetID, urlPath },
                }}
                className='mx-2 btn btn-info btn-sm-inline text-white px-3'
              >
                Sign In
              </Link>
              <Link
                to={{
                  pathname: '/auth/signup',
                  state: { sheetID, urlPath },
                }}
                className='mx-2 btn btn-info btn-sm-inline text-white px-3'
              >
                Sign Up
              </Link>
            </>
          )}
          <button
            disabled={fetch}
            onClick={close}
            className='mx-2 btn btn-outline-info btn-sm-inline px-3'
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SheetAssignModal;
