import { createReducer } from 'utils/reduxHelpers';

const ON_DATA_SET = '@@landing/ON_DATA_SET';

export const onLandingState = payload => ({ type: ON_DATA_SET, payload });

const landingHandlers = {
  [ON_DATA_SET]: (state, action) => action.payload,
};

export const LandingInitialState = {};

export const landingReducer = createReducer(LandingInitialState, landingHandlers);
