import MainLayout from 'app/components/MainLayout';
import React, { useEffect, useState } from 'react';
import GameInfo from './components/GameInfo';
import Leaders from './components/Leaders';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { onLandingState } from './actions';
import TopBanner from 'shared/components/TopBanner';
import LandingSheets from './components/LandingSheets';
import { selectCustomStyles, weeks } from 'configs/consts';
import Select from 'react-select';
import ReactModal from 'react-modal';
import SheetAssignModal from './components/SheetAssignModal';

const constructTeams = teams => {
  const result = {};
  teams.forEach(item => {
    result[item['Key']] = item;
  });
  return result;
};

const constructSeasonData = data => {
  const result = {};
  data.forEach(item => {
    result[item.name] = result[item.name] || [];
    result[item.name].push(item);
  });
  return result;
};

const Landing = () => {
  const url = new URL(window.location.href);
  const fundraisingPath = url.searchParams.get('path');
  const sheetID = url.searchParams.get('sheet');
  const requestPath = fundraisingPath ? `/${fundraisingPath}` : '';
  const dispatch = useDispatch();
  const { landing, isAuthenticated } = useSelector(state => state);
  const [showSheetModal, setShowSheetModal] = useState(false);
  const [week, setWeek] = useState();
  const [currentWeek, setCurrentWeek] = useState();
  const [leaders, setLeaders] = useState();
  const [allGames, setAllGames] = useState(!isAuthenticated);
  const [userOption, setUserOption] = useState({
    userId: null,
  });
  const [userSheets, setUserSheets] = useState();
  const [homeSheets, setHomeSheets] = useState();
  const [fundraisingInfo, setFundraisingInfo] = useState();
  const { teams, games } = landing;

  const getUserSheets = async () => {
    try {
      const sheets = await Api.get(`/users/${userOption.userId}/sheets`, {
        params: {
          sheet_id: userOption.sheetId,
          week,
        },
      });
      setUserSheets(sheets.data);
    } catch (err) {}
  };

  const getFundraisingData = async () => {
    try {
      const { data } = await Api.get(`/common/pool/info${requestPath}`);
      setFundraisingInfo(data);
    } catch (err) {}
  };

  const getHomeSheets = async () => {
    try {
      const { data } = await Api.get('/users/me/sheets', {
        params: { week },
      });
      setHomeSheets(data);
    } catch (err) {}
  };

  const getCurrentWeek = async () => {
    try {
      const currentWeek = await Api.get('/common/current-week');
      setWeek(currentWeek.data.current_week);
      setCurrentWeek(currentWeek.data.current_week);
    } catch (err) {}
  };

  const getGames = async () => {
    try {
      const { data } = await Api.get(`/common/games`, {
        params: { week },
      });
      const teamsData = await Api.get('/common/teams');
      dispatch(onLandingState({ ...landing, games: data, teams: constructTeams(teamsData.data) }));
    } catch (err) {}
  };

  const getLeaders = async () => {
    try {
      const lastWeek = week - 1;
      const currentWeekData = await Api.get(`/common/week-leaders${requestPath}`, {
        params: { week },
      });
      let lastWeekData = false;
      if (lastWeek > 0) {
        lastWeekData = await Api.get(`/common/week-leaders${requestPath}`, {
          params: {
            week: lastWeek,
          },
        });
      }
      const seasonData = await Api.get(`/common/season-leaders${requestPath}`, {
        params: { week, season: 2024 },
      });
      setLeaders({
        lastWeekData: lastWeekData ? constructSeasonData(lastWeekData.data) : [],
        currentWeekData: constructSeasonData(currentWeekData.data),
        seasonData: constructSeasonData(seasonData.data),
      });
    } catch (err) {}
  };

  useEffect(() => {
    if (isAuthenticated) getHomeSheets();
  }, [week]);

  useEffect(() => {
    getCurrentWeek();
    getFundraisingData();
  }, []);

  useEffect(() => {
    if (sheetID) {
      setShowSheetModal(true);
    }
  }, []);

  useEffect(() => {
    if (userOption.userId) getUserSheets();
  }, [userOption, week]);

  useEffect(() => {
    if (week) {
      getGames();
      getLeaders();
    }
  }, [week]);

  const activeWeek = weeks.find(item => item.id === week);

  return (
    <MainLayout week={week} setWeek={setWeek} currentWeek={currentWeek}>
      <div>
        <TopBanner pool={fundraisingInfo} week={week} />
      </div>
      <div className='container justify-content-between pt-10 d-none d-md-flex'>
        <h2 className='welcome-fundraising'>Welcome</h2>
        {setWeek && week && (
          <Select
            styles={selectCustomStyles}
            className='multi-select col-3 p-0'
            classNamePrefix='multi-select-pre'
            getOptionLabel={o => {
              if (currentWeek === o.id) return `${o.name} (current)`;
              return o.name;
            }}
            getOptionValue={o => o.id}
            value={activeWeek}
            placeholder={'Select Week'}
            onChange={o => setWeek(o.id)}
            options={weeks}
          />
        )}
      </div>
      <div className='d-flex flex-wrap container landing-content'>
        {homeSheets && !!homeSheets.length && (
          <div className='landing-sheets px-0 px-sm-3 col-12'>
            <LandingSheets title={'My Sheets'} sheets={homeSheets} currentWeek={week} />
          </div>
        )}
        {userOption.userId && userSheets && (
          <div className='landing-sheets px-0 px-sm-3 col-12'>
            <LandingSheets
              title={`${userOption.name} Sheet`}
              sheets={userSheets}
              currentWeek={week}
              color={userOption.color}
            />
          </div>
        )}
        <div className='col-md-8 px-0 px-sm-3 col-12'>
          {!games || !teams ? (
            <Loading className='mt-5' />
          ) : (
            <GameInfo
              allGames={allGames}
              setAllGames={setAllGames}
              games={games}
              teams={teams}
              sheets={{
                accountSheets: homeSheets,
                selectedUserSheets: userSheets,
              }}
              userOption={userOption}
            />
          )}
        </div>
        <div className='col-md-4 px-0 px-sm-3 col-12'>
          {!leaders ? (
            <Loading className='mt-5' />
          ) : (
            <Leaders
              leaders={leaders}
              userOption={userOption}
              setUserOption={setUserOption}
              allGames={allGames}
              setAllGames={setAllGames}
              setWeek={setWeek}
              currentWeek={currentWeek}
            />
          )}
        </div>
      </div>
      <ReactModal isOpen={showSheetModal} className='custom-modal'>
        <SheetAssignModal
          sheetID={sheetID}
          urlPath={fundraisingPath}
          fundrasingName={fundraisingInfo?.pool_config?.name}
          close={() => {
            setShowSheetModal(false);
            window.history.replaceState(null, null, window.location.pathname);
          }}
        />
      </ReactModal>
    </MainLayout>
  );
};

export default Landing;
