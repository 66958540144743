import packageJson from './../../package.json';
import { getFromStore, saveToStore } from 'utils/storeHelpers';

const url = new URL(window.location.href);
const urlEnv = url.searchParams.get('env');

if (urlEnv && (urlEnv === 'development' || urlEnv === 'production')) {
  saveToStore('env', urlEnv);
}

const generic = {
  production: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.qp3football.com',
    content: 'https://content.qp3football.com',
    app_domain: 'https://qp3football.com/',
    isProdution: true,
  },
  stage: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.pooldev.live',
    content: 'https://content.pooldev.live',
    app_domain: 'https://www.pooldev.live/',
    isProdution: false,
  },
  development: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'dev.pooldev.live',
    content: 'https://content-dev.pooldev.live',
    app_domain: 'https://user-dev.pooldev.live/',
    isProdution: false,
  },
};

const getENV = () => {
  let env = process.env.REACT_APP_API_URL_ENV || process.env.NODE_ENV;
  env = env === 'test' ? 'development' : env;
  return env;
};

const mainEnv = getENV() || 'development';
const storeEnv = getFromStore('env');
const main = generic[storeEnv || mainEnv];

export const HOST = {
  API: {
    VERSION: packageJson.version,
    URL: `${main.url_protocol}://${main.api_domain}`,
    WS: `${main.ws_protocol}://${main.api_domain}`,
    PROTOCOL: `${main.url_protocol}://`,
    API_PREFIX: 'api/v1/',
    AUTH_HEADER: 'Authorization',
    AUTH_PREFIX: 'Bearer',
    CONTENT_URL: main.content,
    APP_URL: main.app_domain,
  },
};

export const GLOBAL = {
  IS_PRODUCTION: main.isProdution,
};
