import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userIcon from 'assets/icons/large-user.svg';
import InputGroup from 'shared/components/InputGroup';
import { Api } from 'utils/connectors';
import { IconEdit, IconEye, IconSave } from 'shared/components/Icons';
import ReactModal from 'react-modal';
import PasswordEditModal from './PasswordEditModal';
import { getProfile, onLogin } from 'app/actions';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { GLOBAL } from 'configs';

const AccountInfo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [editPass, setEditPass] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [fetch, setFetch] = useState(false);
  const account = useSelector(state => state.account?.profile);
  const [form, setForm] = useState({
    firstname: account.firstname,
    email: account.email,
    lastname: account.lastname,
    phone: account.phone,
    username: account.username,
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onUpdateProfile = async () => {
    try {
      setFetch(true);
      await Api.post('/users/me/update', form);
      setIsEdit(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetch(false);
    } finally {
      setFetch(false);
    }
  };

  const updateProfile = async () => {
    try {
      const user = await getProfile();
      await dispatch(onLogin(user));
    } catch (err) {}
  };

  return (
    <div className='d-none d-sm-flex'>
      <div className='border-box d-flex align-items-center justify-content-center small-radius mr-6 user-img-area'>
        <img src={userIcon} alt='user' />
      </div>
      <div className='border-box pb-2 flex-fill d-flex'>
        <ul className='d-flex flex-wrap m-0 p-0'>
          <li className={`col-6 d-flex align-items-center px-1 ${isEdit ? 'mb-2' : 'mb-4'}`}>
            <div className='col-6 p-0 font-weight-bold'>First Name</div>
            <div className='col-6 pl-0'>
              <InputGroup
                showOnlyValue={!isEdit}
                containerClass='mb-0'
                name='firstname'
                type='text'
                value={form.firstname}
                placeholder={'Enter First Name'}
                onChange={handleChange}
                required
              />
            </div>
          </li>
          <li className={`col-6 d-flex align-items-center px-1 ${isEdit ? 'mb-2' : 'mb-4'}`}>
            <div className='col-6 p-0 font-weight-bold'>Email</div>
            <div className='col-6 pl-0'>
              <InputGroup
                showOnlyValue={!isEdit}
                containerClass='mb-0'
                name='email'
                type='email'
                value={form.email}
                placeholder={'Enter Email'}
                onChange={handleChange}
                disabled={GLOBAL.IS_PRODUCTION}
                required
              />
            </div>
          </li>
          <li className={`col-6 d-flex align-items-center px-1 ${isEdit ? 'mb-2' : 'mb-4'}`}>
            <div className='col-6 p-0 font-weight-bold'>Last Name</div>
            <div className='col-6 pl-0'>
              <InputGroup
                showOnlyValue={!isEdit}
                containerClass='mb-0'
                name='lastname'
                type='text'
                value={form.lastname}
                placeholder={'Enter Last name'}
                onChange={handleChange}
                required
              />
            </div>
          </li>
          <li className={`col-6 d-flex align-items-center px-1 ${isEdit ? 'mb-2' : 'mb-4'}`}>
            <div className='col-6 p-0 font-weight-bold'>Contact No.</div>
            <div className='col-6 pl-0'>
              <InputGroup
                showOnlyValue={!isEdit}
                containerClass='mb-0'
                name='phone'
                type='text'
                value={form.phone}
                placeholder={'Enter Phone'}
                onChange={handleChange}
                required
              />
            </div>
          </li>
          <li className='col-6 d-flex align-items-center px-1'>
            <div className='col-6 p-0 font-weight-bold'>Username</div>
            <div className='col-6 pl-0'>
              <InputGroup
                showOnlyValue={!isEdit}
                containerClass='mb-0'
                name='username'
                type='text'
                value={form.username}
                placeholder={'Enter Username'}
                onChange={handleChange}
                required
              />
            </div>
          </li>
          <li className='col-6 d-flex align-items-center px-1'>
            <div className='col-6 p-0 font-weight-bold'>Password</div>
            <div className={`col-6 d-flex align-items-center ${isEdit ? 'pr-0' : 'px-0'}`}>
              <div className='col-6 p-0'>
                {showPass ? account.plain_password : <span>*********</span>}
              </div>
              <div className='d-flex col p-0'>
                <button
                  className={`btn p-0 pass-btns btn-non-shadow ${showPass ? 'active' : ''}`}
                  onClick={() => setShowPass(i => !i)}
                >
                  <IconEye />
                </button>
                <button
                  className='btn p-0 pass-btns btn-non-shadow ml-2'
                  onClick={() => setEditPass(i => !i)}
                >
                  <IconEdit />
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div>
          <button
            className='btn save-btn'
            disabled={fetch}
            onClick={isEdit ? onUpdateProfile : setIsEdit.bind(null, true)}
          >
            {isEdit ? <IconSave /> : <IconEdit />}
          </button>
        </div>
      </div>
      <ReactModal isOpen={editPass} className='custom-modal'>
        <PasswordEditModal onSuccess={updateProfile} close={() => setEditPass(false)} />
      </ReactModal>
    </div>
  );
};

export default AccountInfo;
