import React from 'react';
import TopMobileBanner from 'shared/components/TopMobileBanner';
import Header from './Header';

const MainLayout = ({ className, children, week, setWeek, currentWeek }) => {
  return (
    <div id='content' className='container-fluid d-flex flex-column p-0'>
      <Header />
      <div className='d-block d-sm-none'>
        <TopMobileBanner week={week} setWeek={setWeek} currentWeek={currentWeek} />
      </div>
      <main className={`flex-fill ${className || ''}`} role='main'>
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
