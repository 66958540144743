import React, { useEffect, useState } from 'react';
import { Api } from 'utils/connectors';
import { seasonPosIcons } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import MainLayout from 'app/components/MainLayout';
import medalOrange from 'assets/icons/medal-orange.svg';
import flagOrange from 'assets/icons/flag-orange.svg';
import flag from 'assets/icons/flag.svg';
import flagRed from 'assets/icons/flag-red.svg';
import { IconArrowLeft, IconArrowRight, IconPrint } from 'shared/components/Icons';

const getTeamWLT = point => {
  if (point === null) return null;
  return point === 0 ? 'L' : point === 1 ? 'T' : 'W';
};

const SheetView = ({ match, history }) => {
  const [printFetch, setPrintFetch] = useState(false);
  const { id } = match.params;
  const [data, setData] = useState();
  const [prevNext, setPrevNext] = useState({});

  const getSheetData = async () => {
    try {
      const { data } = await Api.get(`/sheet/${id}/week`);
      setData(data.sheets ? data.sheets[0] : data[0]);
      setPrevNext({ prev_sheet_id: data.prev_sheet_id, next_sheet_id: data.next_sheet_id });
    } catch (err) {}
  };

  const onSheetPrint = async () => {
    try {
      setPrintFetch(true);
      const { data } = await Api.get(`/sheet/${id}/print`);
      window.open(data);
    } catch (err) {
    } finally {
      setPrintFetch(false);
    }
  };

  const goToSheetView = id => {
    history.push(`/sheet/${id}/view`);
  };

  useEffect(() => {
    getSheetData();
  }, [id]);

  if (!data)
    return (
      <MainLayout className='theme-light'>
        <Loading className='mt-5' />;
      </MainLayout>
    );

  return (
    <MainLayout className='theme-light'>
      <div className='container sheets-page'>
        <div className='sheet-view-head justify-content-between px-10 col-12 d-flex align-items-center mb-5 px-5'>
          <div className='d-flex'>
            <button className='view-btn mr-2 bg-white' disabled={printFetch} onClick={onSheetPrint}>
              <IconPrint />
            </button>
          </div>
          <div className='d-flex'>
            <div className='ml-2 icon-indicator'>
              <img src={data.season_leader ? flagRed : flag} alt='medal' />
            </div>
            <div className='ml-2 icon-indicator'>
              <img
                src={
                  seasonPosIcons[
                    data.season_winner === null || data.season_winner === undefined
                      ? 4
                      : data.season_winner
                  ]
                }
                alt='medal'
              />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center col-12 pb-5 align-items-center'>
          <button
            onClick={goToSheetView.bind(null, prevNext.prev_sheet_id)}
            disabled={!prevNext.prev_sheet_id}
            className='btn btn-arrows'
          >
            <IconArrowLeft height='20' />
          </button>
          <div className='sheet-view-table mx-2'>
            <div className='table-header d-flex justify-content-between align-items-center'>
              <div>ID:{data.sheetId}</div>
              <h4>{data.poolname}</h4>
              <div>{data.fullname}</div>
            </div>
            <table className='sheet-view-table'>
              <thead>
                <tr>
                  <th>Player 1</th>
                  <th>Team 1</th>
                  <th>
                    (W)in / <br />
                    (L)oss / <br />
                    (T)ie
                  </th>
                  <th>Score</th>
                  <th>Points</th>
                  <th>Team 2</th>
                  <th>
                    (W)in / <br />
                    (L)oss / <br />
                    (T)ie
                  </th>
                  <th>Score</th>
                  <th>Points</th>
                  <th>Team 3</th>
                  <th>
                    (W)in / <br />
                    (L)oss / <br />
                    (T)ie
                  </th>
                  <th>Score</th>
                  <th>Points</th>
                  <th>
                    <u>Week</u> <br />
                    <u>Total</u> <br />
                    <u>Score</u>
                  </th>
                  <th>
                    <u>Season</u> <br />
                    <u>Weekly</u> <br />
                    <u>Points</u>
                  </th>
                  <th>
                    <u>Week</u> <br />
                    <u>Status</u> <br />
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.weeks.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>Week {item.week}</td>
                      <td>{item.t1_name}</td>
                      <td className='text-center'>{getTeamWLT(item.t1_points)}</td>
                      <td>{item.t1_score}</td>
                      <td>{item.t1_points}</td>
                      <td>{item.t2_name}</td>
                      <td className='text-center'>{getTeamWLT(item.t2_points)}</td>
                      <td>{item.t2_score}</td>
                      <td>{item.t2_points}</td>
                      <td>{item.t3_name}</td>
                      <td className='text-center'>{getTeamWLT(item.t3_points)}</td>
                      <td>{item.t3_score}</td>
                      <td>{item.t3_points}</td>
                      <td>{item.wts}</td>
                      <td>{item.swp}</td>
                      <td>
                        {item.week_leader === 1 && item.week_winner !== 1 && (
                          <img className='mx-1' src={flagOrange} alt='icon' />
                        )}
                        {item.week_winner === 1 && (
                          <img className='mx-1' src={medalOrange} alt='icon' />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <button
            onClick={goToSheetView.bind(null, prevNext.next_sheet_id)}
            disabled={!prevNext.next_sheet_id}
            className='btn btn-arrows'
          >
            <IconArrowRight height='20' />
          </button>
        </div>
      </div>
    </MainLayout>
  );
};

export default SheetView;
