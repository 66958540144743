import React, { useEffect, useRef, useState } from 'react';
import { getError, getGameState } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import Loading from 'shared/components/Loading';
import Filters from './components/Filters';
import useDebounce from 'shared/hooks/useDebounce';
import { useSelector } from 'react-redux';
import { weeks } from 'configs/consts';

const PooSurvivalStatsView = ({ match }) => {
  const isAuth = useSelector(state => state.isAuthenticated);
  const statsColumFixed = useRef();
  const statsBody = useRef();
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const [stats, setStats] = useState();
  const [printFetch, setPrintFetch] = useState(false);
  const [activeType, setActiveType] = useState({ name: 'All', type: 'all' });
  const [search, setSearch] = useState('');
  const [currentWeek, setCurrentWeek] = useState();
  const debounceSearch = useDebounce(search, 300);

  const getPoolData = async id => {
    try {
      const currentWeek = await Api.get('/common/current-week');
      setCurrentWeek(currentWeek.data.current_week);
      const { data } = await Api.get(`/admin/pool/${id}?type=survival`);
      setData(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onGetState = async () => {
    try {
      setPrintFetch(true);
      const isSelectedAll = activeType.type === 'all';
      const { data } = await Api.get(`/admin/pool/${id}/player-stats`, {
        params: {
          filter: isSelectedAll ? undefined : activeType.type,
          search: debounceSearch,
        },
      });
      const result = [];
      data.forEach(person => {
        person.participants.forEach(entry => {
          const row = { owner_name: person.owner_name, ...entry };
          if (!isSelectedAll) {
            if (activeType.type === 0) row.undefeated = true;
            else if (activeType.type === -1) row.out = true;
            else row.tier = activeType.type;
          }
          result.push(row);
        });
      });
      setStats(result);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setPrintFetch(false);
    }
  };

  const onScrollFixedElement = e => {
    statsBody.current.scrollTop = statsColumFixed.current.scrollTop;
  };

  const onScrollBodyElement = e => {
    statsColumFixed.current.scrollTop = statsBody.current.scrollTop;
  };

  const onSheetPrint = async () => {
    try {
      setPrintFetch(true);
      const { data } = await Api.get(`/admin/pool/${id}/player-stats?print=1`);
      window.open(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setPrintFetch(false);
    }
  };

  useEffect(() => {
    if (id) getPoolData(id);
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (stats) {
      setTimeout(() => {
        statsColumFixed.current?.addEventListener('scroll', onScrollFixedElement);
        statsBody.current?.addEventListener('scroll', onScrollBodyElement);
      }, 1000);
    }

    return () => {
      statsColumFixed.current?.removeEventListener('scroll', onScrollFixedElement);
      statsBody.current?.removeEventListener('scroll', onScrollBodyElement);
    };
  }, [stats]);

  useEffect(() => {
    if (id) onGetState();
  }, [activeType, debounceSearch]);

  if (!data || !stats) return <Loading />;

  return (
    <div className={isAuth ? '' : 'stats-public-view'}>
      <div className={`${isAuth ? 'has-header' : ''}`}>
        <Filters
          printFetch={printFetch}
          data={data}
          search={search}
          setSearch={setSearch}
          activeType={activeType}
          setActiveType={setActiveType}
          onSheetPrint={onSheetPrint}
        />
        <div className='col-12 px-8 pb-5'>
          <div className='player-survival-stats d-flex flex-column'>
            <div className='stats-header d-flex align-items-center justify-content-between'>
              <p className='mb-0'>{activeType.name}</p>
              <p className='mb-0'>{data.pool_config.name}</p>
              <p className='mb-0'>ID: {id}</p>
            </div>
            <div className='stats-body is-fixed d-flex' ref={statsBody}>
              <div className='stats-colum has-shadow is-fixed' ref={statsColumFixed}>
                <div className='colum-header d-flex'>
                  <div className='d-flex h-100 w-100'>
                    <div className='row-item col row-p-md row-l text-nowrap'>Team Owner</div>
                    <div className='row-item col row-p-md row-l'>Entry Name</div>
                    <div className='row-item col row-p-md row-m border-right-none'>Tier</div>
                  </div>
                </div>
                {stats.map((row, i) => {
                  return (
                    <div className='colum-item d-flex' key={i}>
                      <div className='d-flex h-100 w-100'>
                        <div className='row-item col row-l row-p-md text-nowrap'>
                          {row.owner_name}
                        </div>
                        <div className='row-item col row-l row-p-md'>{row.entrance_name}</div>
                        <div className='row-item col row-m row-p-md border-right-none'>
                          {row.undefeated && 'Tier-0'}
                          {row.out && 'Out'}
                          {row.tier && `Tier-${row.tier}`}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {weeks.map((week, i) => {
                const isCurrentWeek = currentWeek === week.id;
                return (
                  <div className='stats-colum' key={i}>
                    <div
                      className={`pl-4 colum-header row-l row-item text-nowrap d-flex align-items-center justify-content-between ${isCurrentWeek &&
                        'bg-current'}`}
                    >
                      <p className='mb-0 d-flex align-items-center'>
                        {isCurrentWeek && <span>●</span>} {week.name}
                      </p>
                      <p className='border-left border-black pl-2 mb-0 col-3'>Score</p>
                    </div>
                    {stats.map((row, i) => {
                      const currentWeek = row.week_data.find(i => i.week === week.id);
                      const gameState = getGameState(currentWeek);
                      return (
                        <div
                          className={`pl-4 colum-item row-l row-item text-nowrap d-flex align-items-center justify-content-between bg-${gameState} ${isCurrentWeek &&
                            'bg-current'}`}
                          key={i}
                        >
                          {currentWeek?.team || '-'}
                          <p className='border-left border-black pl-2 mb-0 col-3'>
                            {currentWeek?.score || '-'}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PooSurvivalStatsView;
