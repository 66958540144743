import React, { useState } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { Link } from 'react-router-dom';
import InputGroup from 'shared/components/InputGroup';
import SuccessText from '../components/SuccessText';
import { useLocation } from 'react-router-dom';

const Signup = () => {
  const location = useLocation();
  const [fetch, setFetch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState();
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: undefined,
    password: undefined,
    username: '',
    role: 'player',
    phone: undefined,
    sheetId: location.state?.sheetID || undefined,
    urlPath: location.state?.urlPath || undefined,
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onBlurHandle = ({ target }) => {
    const { name } = target;
    if (
      (name === 'firstname' || name === 'lastname') &&
      !form.username &&
      form.firstname &&
      form.lastname
    ) {
      setForm({ ...form, username: `${form.firstname}${form.lastname}` });
    }
  };

  const onFormSubmit = async e => {
    setFetch(true);
    e.preventDefault();
    try {
      if (form.username) form.username = form.username.replace(/\s/g, '');
      await Api.post('/users/register', form);
      setSuccess(true);
    } catch (error) {
      setErr(getError(error));
      setFetch(false);
    }
  };

  const passMisMatch = form.cpassword && form.password !== form.cpassword;

  if (success) return <SuccessText text='Successfully Registered' />;

  return (
    <div className='d-flex signup-page flex-column col-md-8 col-12 m-auto m-md-0'>
      <h2 className='mb-4 font-weight-bold'>Create Account</h2>
      <form onSubmit={onFormSubmit} className='col-12 p-0'>
        <InputGroup
          label='First Name'
          name='firstname'
          type='text'
          value={form.firstname || ''}
          placeholder={'Enter First Name'}
          onChange={handleChange}
          errorText={!!err}
          onBlur={onBlurHandle}
          required
        />
        <InputGroup
          label='Last Name'
          name='lastname'
          type='text'
          value={form.lastname || ''}
          placeholder={'Enter Last Name'}
          onChange={handleChange}
          errorText={!!err}
          onBlur={onBlurHandle}
          required
        />
        <InputGroup
          label='Phone'
          name='phone'
          type='number'
          value={form.phone || ''}
          placeholder={'Enter Phone'}
          onChange={handleChange}
          errorText={!!err}
        />
        <InputGroup
          label='Username'
          name='username'
          type='text'
          value={form.username || ''}
          placeholder={'Enter Username'}
          onChange={handleChange}
          errorText={!!err}
          required
        />
        <InputGroup
          label='Email'
          name='email'
          type='email'
          value={form.email || ''}
          placeholder={'Enter Email'}
          onChange={handleChange}
          errorText={!!err}
        />
        <InputGroup
          label='Password'
          name='password'
          type='password'
          value={form.password || ''}
          placeholder={'Enter Password'}
          onChange={handleChange}
          errorText={err}
        />
        <InputGroup
          label='Confirm Password'
          name='cpassword'
          type='password'
          value={form.cpassword || ''}
          placeholder={'Repeat Password'}
          onChange={handleChange}
          errorText={passMisMatch ? 'Passwords mismatch' : err}
        />
        <div className='text-center text-md-right mt-4'>
          <button
            type='submit'
            disabled={fetch || passMisMatch}
            className='btn btn-info btn-block btn-sm-inline text-white px-5'
          >
            Create
          </button>
        </div>
        <p className='text-center text-md-right mt-3 mb-0 modal-footer-link'>
          Already have an account? <Link to='/auth/signin'>Sign in</Link>
        </p>
      </form>
    </div>
  );
};

export default Signup;
