import React from 'react';

const getTeamWLT = point => {
  if (point === null) return ' ';
  return point === 0 ? 'L' : point === 1 ? 'T' : 'W';
};

const LandingSheets = ({ sheets, currentWeek, title, color }) => {
  // const sheetsData = sheets.filter(({ weeks }) => weeks[0] && weeks[0].week === currentWeek);

  return (
    <div>
      {sheets && !!sheets.length && (
        <h2 className='landing-title text-center pl-0 pl-sm-5 text-sm-left'>{title}</h2>
      )}

      {sheets.map((item, key) => {
        const week = item.weeks ? item.weeks[0] : null;
        if (!week) return null;
        return (
          <div className={`sheet-block ${color ? 'color-' + color : ''}`} key={key}>
            <div className='sb-header d-flex align-items-center justify-content-between'>
              <div>ID: {item.sheetId}</div>
              <div className='text-large font-weight-bold text-right text-sm-center'>
                NFL - {item.season} - {item.poolname}
              </div>
              <div className='d-none d-sm-block'>Week {week.week}</div>
            </div>
            <div className='sb-body d-flex flex-column flex-sm-row align-items-center justify-content-between'>
              <div className='d-flex list-body'>
                <div className='first-row pl-0'>
                  <p>Team 1</p>
                  <p className='value extra-large'>{week.t1_name}</p>
                </div>
                <div className='mid-row'>
                  <p className='value'>{getTeamWLT(week.t1_points)}</p>
                  <p className='d-block d-sm-none'>W/L/T</p>
                </div>
                <div className='last-row'>
                  <p className='value'>{week.t1_score || ' '}</p>
                  <p className='d-block d-sm-none'>Score</p>
                </div>
              </div>
              <div className='d-flex list-body justify-content-center'>
                <div className='first-row pl-0'>
                  <p>Team 2</p>
                  <p className='value extra-large'>{week.t2_name}</p>
                </div>
                <div className='mid-row'>
                  <p className='value'>{getTeamWLT(week.t2_points)}</p>
                  <p className='d-block d-sm-none'>W/L/T</p>
                </div>
                <div className='last-row'>
                  <p className='value'>{week.t2_score || ' '}</p>
                  <p className='d-block d-sm-none'>Score</p>
                </div>
              </div>
              <div className='d-flex list-body justify-content-center'>
                <div className='first-row pl-0'>
                  <p>Team 3</p>
                  <p className='value extra-large'>{week.t3_name}</p>
                </div>
                <div className='mid-row'>
                  <p className='value'>{getTeamWLT(week.t3_points)}</p>
                  <p className='d-block d-sm-none'>W/L/T</p>
                </div>
                <div className='last-row'>
                  <p className='value'>{week.t3_score || ' '}</p>
                  <p className='d-block d-sm-none'>Score</p>
                </div>
              </div>
              <div className='d-flex list-body justify-content-end'>
                <div className='px-0 mr-3'>
                  <p>Week Points</p>
                  <p className='value large text-center'>{week.swp}</p>
                </div>
                <div className='px-0'>
                  <p>Week Score</p>
                  <p className='value large text-center'>{week.wts}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LandingSheets;
