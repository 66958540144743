import React, { useState } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { Link } from 'react-router-dom';
import InputGroup from 'shared/components/InputGroup';
import SuccessText from '../components/SuccessText';

const Forgot = () => {
  const [fetch, setFetch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState();
  const [form, setForm] = useState({
    user_email: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onFormSubmit = async e => {
    setFetch(true);
    e.preventDefault();
    try {
      await Api.post('/users/forgotpassword', form);
      setSuccess(true);
    } catch (error) {
      setErr(getError(error));
      setFetch(false);
    }
  };

  if (success) return <SuccessText text='Email Successfully Sent' />;

  return (
    <div className='d-flex forgot-page flex-column col-md-8 col-12 m-auto m-md-0'>
      <h2 className='mb-4 font-weight-bold'>Forgot Password</h2>
      <form onSubmit={onFormSubmit} className='col-12 p-0'>
        <InputGroup
          label='Email'
          name='user_email'
          type='email'
          value={form.user_email}
          placeholder={'Enter Email'}
          onChange={handleChange}
          errorText={!!err}
          required
        />
        <div className='text-center text-md-right mt-4'>
          <button
            type='submit'
            disabled={fetch}
            className='btn btn-block btn-sm-inline mt-6 mt-sm-0 btn-info text-white px-5'
          >
            Submit
          </button>
        </div>
        <p className='text-center text-md-right mt-3 mb-0 modal-footer-link'>
          Already have an account? <Link to='/auth/signin'>Sign in</Link>
        </p>
      </form>
    </div>
  );
};

export default Forgot;
