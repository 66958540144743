import React from 'react';
import { Link } from 'react-router-dom';
import successImg from 'assets/icons/success.svg';

const SuccessText = ({ text }) => {
  return (
    <div className='d-flex text-center signup-success-page flex-column col-12 m-md-0'>
      <img width='90' className='m-auto' src={successImg} alt='success' />
      <h2 className='mb-10 mt-5 font-weight-bold'>{text}</h2>
      <div className='text-center mt-5'>
        <Link to='/home' className='btn btn-info text-white px-5'>
          Home
        </Link>
      </div>
    </div>
  );
};

export default SuccessText;
