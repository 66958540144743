import React, { useState } from 'react';

import medal from 'assets/icons/medal.svg';
import medalOrange from 'assets/icons/medal-orange.svg';
import medal1 from 'assets/icons/medal-1.svg';
import medal2 from 'assets/icons/medal-2.svg';
import medal3 from 'assets/icons/medal-3.svg';
import medalL from 'assets/icons/medal-l.svg';
import medalNull from 'assets/icons/medal-null.svg';
import flag from 'assets/icons/flag.svg';
import flagOrange from 'assets/icons/flag-orange.svg';
import flagRed from 'assets/icons/flag-red.svg';
import arrow from 'assets/icons/arrow-up.svg';
import { useSelector } from 'react-redux';

const posIcons = {
  0: medalL,
  1: medal1,
  2: medal2,
  3: medal3,
  4: medalNull,
};

const SheetsBlockMobile = () => {
  const sheets = useSelector(state => state.account.sheets);
  const [boxToggle, setBoxToggle] = useState(true);

  return (
    <div className='sheets-area-mobile d-block d-sm-none'>
      <div className={`m-drop-menu ${boxToggle ? 'active' : 'closed'}`}>
        <div className='m-drop-header d-flex align-items-center justify-content-center'>
          <p className='m-0'>NFL 2024</p>
          <button
            className={`btn p-0 btn-non-shadow m-drop-toggle ${boxToggle ? 'active' : ''}`}
            onClick={() => setBoxToggle(!boxToggle)}
          >
            <img src={arrow} alt='arrow' />
          </button>
        </div>
        {boxToggle && (
          <div className='m-drop-body'>
            {sheets.map((item, i) => {
              return (
                <div className='sheet-m-item' key={i}>
                  <p>Sheet ID: {item.sheetId}</p>
                  <div className='d-flex flex-wrap'>
                    <div className='sheet-col col-4 mb-4 p-0 text-center'>
                      <span className='win-pct'>{item.winning_pct || '-'}</span>
                      <p className='mb-0 item-label'>Winning Pct</p>
                    </div>
                    <div className='sheet-col col-4 mb-4 p-0 middle-item'>
                      <img src={item.week_winner ? medalOrange : medal} alt='medal' />
                      <p className='mb-0 item-label'>Weekly</p>
                    </div>
                    <div className='sheet-col col-4 mb-4 p-0 text-center'>
                      <img src={item.week_leader ? flagOrange : flag} alt='medal' />
                      <p className='mb-0 item-label'>Week Leader</p>
                    </div>
                    <div className='sheet-col col-4 p-0 text-center'>
                      <div className='compare-block'>
                        <span>{item.points_of_week || 0}</span>
                        <span>|</span>
                        <span>{item.points_of_season || 0}</span>
                      </div>
                      <p className='mb-0 item-label'>Points</p>
                    </div>
                    <div className='sheet-col col-4 p-0 middle-item'>
                      <img
                        src={posIcons[item.season_winner === null ? 4 : item.season_winner]}
                        alt='medal'
                      />
                      <p className='mb-0 item-label'>Season</p>
                    </div>
                    <div className='sheet-col col-4 p-0 text-center'>
                      <img src={item.season_leader ? flagRed : flag} alt='medal' />
                      <p className='mb-0 item-label'>Season Leader</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SheetsBlockMobile;
