import React, { useState } from 'react';
import userIcon from 'assets/icons/large-user.svg';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { logout } from 'app/actions';
import { useDispatch } from 'react-redux';
import IconEyeSlash from 'assets/icons/eye.svg';
import Select from 'react-select';
import { selectCustomStyles, weeks } from 'configs/consts';
import { useHistory } from 'react-router';
import fundraisingMan from 'assets/fundraising-man-mob.png';

const TopMobileBanner = ({ setWeek, week, currentWeek }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const account = useSelector(state => state.account?.profile);
  const showProfileInfo = location.pathname === '/sheets' && account;
  const [showPass, setShowPass] = useState(false);
  const activeWeek = weeks.find(item => item.id === week);

  const onLogoutClick = () => {
    dispatch(logout());
  };

  const goHomePage = () => {
    history.push('/home');
  };

  return (
    <>
      <div className='landing-banner mobile d-flex justify-content-between align-items-center'>
        <div className='container flex-column'>
          <p className='d-sm-none mobile-text' onClick={goHomePage}>
            Welcome to Fundraising Name
          </p>
          {setWeek && week && (
            <Select
              styles={selectCustomStyles}
              className='multi-select col-6 p-0'
              classNamePrefix='multi-select-pre'
              getOptionLabel={o => {
                if (currentWeek === o.id) return `${o.name} (current)`;
                return o.name;
              }}
              getOptionValue={o => o.id}
              value={activeWeek}
              placeholder={'Select Week'}
              onChange={o => setWeek(o.id)}
              options={weeks}
            />
          )}
        </div>
        <img height='140' src={fundraisingMan} alt='home' />
      </div>
      {account && (
        <div className='mobile-top-menu'>
          <div className='avatar d-flex justify-content-center align-items-center m-auto'>
            <img height='40' src={userIcon} alt='user' />
          </div>
          <p>
            {account.firstname} {account.lastname}
          </p>
          {showProfileInfo && (
            <div className='profile-info mb-2'>
              <ul className='d-flex flex-column m-0 p-0'>
                <li className='col-12 d-flex px-1 mb-3'>
                  <div className='col-4 p-0 font-weight-bold'>First Name</div>
                  <div className='col-8 p-0'>{account.firstname}</div>
                </li>
                <li className='col-12 d-flex px-1 mb-3'>
                  <div className='col-4 p-0 font-weight-bold'>Last Name</div>
                  <div className='col-8 p-0'>{account.lastname}</div>
                </li>
                <li className='col-12 d-flex px-1 mb-3'>
                  <div className='col-4 p-0 font-weight-bold'>Username</div>
                  <div className='col-8 p-0'>{account.username}</div>
                </li>
                <li className='col-12 d-flex px-1 mb-3'>
                  <div className='col-4 p-0 font-weight-bold'>Email</div>
                  <div className='col-8 p-0'>{account.email}</div>
                </li>
                <li className='col-12 d-flex px-1'>
                  <div className='col-4 p-0 font-weight-bold'>Contact No.</div>
                  <div className='col-8 p-0'>{account.phone}</div>
                </li>
                <li className='col-12 d-flex px-1'>
                  <div className='col-4 p-0 font-weight-bold'>Password</div>
                  <div className='col-8 p-0'>
                    {showPass ? account.plain_password : <span>*********</span>}
                    <button
                      className='btn p-0 btn-non-shadow ml-10'
                      onClick={() => setShowPass(i => !i)}
                    >
                      <img className='mb-1' src={IconEyeSlash} alt='eye' />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          )}
          <div className='mobile-menu py-2'>
            <NavLink
              className='btn btn-outline-info mr-3 btn-sm'
              to='/home'
              activeClassName='btn-info-light'
            >
              Home
            </NavLink>
            <NavLink
              className='btn btn-outline-info mr-3 btn-sm'
              to='/sheets'
              activeClassName='btn-info-light'
            >
              Sheets
            </NavLink>
            <button className='btn btn-outline-info mr-3 btn-sm' onClick={onLogoutClick}>
              Log Out
            </button>
          </div>
        </div>
      )}
      {!account && (
        <div className='mobile-top-menu not-login'>
          <div className='mobile-menu py-2'>
            <NavLink
              className='btn btn-outline-info mr-3 btn-sm'
              to='/auth/signin'
              activeClassName='btn-info-light'
            >
              Log In
            </NavLink>
            {/* <NavLink
              className='btn btn-outline-info mr-3 btn-sm'
              to='/auth/signup'
              activeClassName='btn-info-light'
            >
              Sign Up
            </NavLink> */}
          </div>
        </div>
      )}
    </>
  );
};

export default TopMobileBanner;
