export const weeks = [
  { id: 1, name: 'Week 1' },
  { id: 2, name: 'Week 2' },
  { id: 3, name: 'Week 3' },
  { id: 4, name: 'Week 4' },
  { id: 5, name: 'Week 5' },
  { id: 6, name: 'Week 6' },
  { id: 7, name: 'Week 7' },
  { id: 8, name: 'Week 8' },
  { id: 9, name: 'Week 9' },
  { id: 10, name: 'Week 10' },
  { id: 11, name: 'Week 11' },
  { id: 12, name: 'Week 12' },
  { id: 13, name: 'Week 13' },
  { id: 14, name: 'Week 14' },
  { id: 15, name: 'Week 15' },
  { id: 16, name: 'Week 16' },
  { id: 17, name: 'Week 17' },
  { id: 18, name: 'Week 18' },
];

export const playoffWeeks = [
  { id: 19, name: 'Wild Card Round' },
  { id: 20, name: 'Divisional round' },
  { id: 21, name: 'Conference championships' },
  { id: 22, name: 'Super Bowl' },
];

export const allWeeks = [...weeks, ...playoffWeeks];

export const selectCustomStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    fontWeight: 300,
    color: '#000',
  }),
};
