import React, { useState } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { Link } from 'react-router-dom';
import { loginUser } from 'app/actions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import InputGroup from 'shared/components/InputGroup';

const Signin = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [fetch, setFetch] = useState(false);
  const [err, setErr] = useState();
  const [form, setForm] = useState({
    username: '',
    password: '',
    sheetId: location.state?.sheetID || undefined,
    urlPath: location.state?.urlPath || undefined,
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onFormSubmit = async e => {
    setFetch(true);
    e.preventDefault();
    try {
      const { data } = await Api.post('/auth/login', form);
      await dispatch(loginUser(data));
    } catch (error) {
      setErr(getError(error));
      setFetch(false);
    }
  };

  return (
    <div className='d-flex signin-page flex-column col-md-8 col-12 px-0 m-auto m-md-0'>
      <h2 className='mb-4 font-weight-bold'>Sign In</h2>
      <form onSubmit={onFormSubmit} className='col-12 p-0'>
        <InputGroup
          label='Username'
          name='username'
          type='text'
          value={form.username}
          placeholder={'Enter Username'}
          onChange={handleChange}
          errorText={!!err}
          required
        />
        <InputGroup
          label='Password'
          name='password'
          type='password'
          value={form.password}
          placeholder={'Enter Password'}
          onChange={handleChange}
          errorText={err}
          required
        />
        <div className='form-button text-right mt-4'>
          <button type='submit' disabled={fetch} className='btn btn-info text-white px-5'>
            Sign In
          </button>
        </div>
        {/* <p className='text-center text-md-right mt-3 mb-0 modal-footer-link'>
          Don't have an account yet? <Link to='/auth/signup'>Register</Link>
        </p> */}
        <p className='text-center text-md-right mt-3'>
          <Link to='/auth/forgot' role='presentation'>
            Forgot your password?
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Signin;
