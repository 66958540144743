import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import InputGroup from 'shared/components/InputGroup';

const PasswordEditModal = ({ close, onSuccess }) => {
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({
    password: '',
    cpassword: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onAssignSheet = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      await Api.post('/users/me/update-password', form);
      if (onSuccess) onSuccess();
      enqueueSnackbar('Password successfully changed!', { variant: 'success' });
      close();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const passMisMatch = form.cpassword && form.password !== form.cpassword;

  return (
    <div className='modal-box change-password-modal d-flex flex-column'>
      <div className='m-header'>
        <h3 className='text-center'>Change Password</h3>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-content mt-3 flex-fill'>
        <form
          onSubmit={onAssignSheet}
          className='col-12 p-0 h-100 justify-content-between d-flex flex-column'
        >
          <div>
            <InputGroup
              label='New Password'
              name='password'
              type='password'
              value={form.password || ''}
              placeholder={'Enter New Password'}
              onChange={handleChange}
              required
            />
            <InputGroup
              label='Repeat New Password'
              name='cpassword'
              type='password'
              value={form.cpassword || ''}
              placeholder={'Enter New Password'}
              onChange={handleChange}
              errorText={passMisMatch ? 'Passwords mismatch' : ''}
              required
            />
          </div>
          <div className='text-center mt-4'>
            <button
              type='submit'
              disabled={fetch || passMisMatch}
              className='btn btn-info btn-sm-inline text-white px-5'
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordEditModal;
